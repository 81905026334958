
* { box-sizing: border-box; }

html, body { width: 100%; height: 100%; margin: 0; padding: 0; }

body {
	-webkit-text-size-adjust: none;			/* prevent webkit from resizing text to fit */
	-webkit-font-smoothing: antialiased; 	/* Fix for webkit rendering */

	font: 16px/22px #{$fontfamily};
	font-weight: 200;
	line-height: 1.2;
	color: $baseTextColor;
	min-width: 275px;

	background: $defaultBgColor;
	background: radial-gradient( circle, #{lighten($defaultBgColor, 50%)} 0%, #{$defaultBgColor} 100%);
	background-attachment: fixed;
}

@import '../modules/_forms';

/* generic styles */
::selection {
  background: $baseTextColor;
  color: $defaultBgColor;
}
pre {
	 font-family: monospace;
	 white-space: pre;
	 text-align: left;
}
a {
	outline: none;
	color: $accent;
	&:visited { color: $accent; }
	&:hover { text-decoration: none; color: $accent; }
}
strong {
	font-weight: 800;
}
em {
	font-style: italic;
}
.big {
	font-size: 2em;
	line-height: 1;
}
.overflow { overflow: hidden; }
.hidden { display: none; }
.clear { clear: both; }
.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

div.container {
	max-width: 1200px;
	width: 95%;
	margin: 0 auto;
}

header {
	line-height: 1;
	margin: 40px 0 40px;

	svg.logo {
		display: none;
		fill: $baseTextColor;
		width: 80%;
		max-width: 500px;
		margin: 0 0 10px;

		&.active { display: block; }
	}

	.japanese_note {
		display: none;
		&.show { display: block; }
	}

	h1 {
		font-weight: 400;
		font-size: 34px;
		letter-spacing: -1px;
		text-transform: lowercase;
		color: $baseTextColor;
	}
	nav {
		margin-top: 5px;
		text-transform: lowercase;

		font-size: 28px;
		font-weight: 300;

		li {
			display: inline-block;
			&:after {
				content: '/';
				color: $baseTextColor;
				padding-right: 0.2em;
			}
			&:last-of-type:after { display: none; }
		}
		a {
			position: relative;
			display: inline-block;
			padding: 0 0.5em 0 0;
			text-decoration: none;
			color: $accent;
			transition: color 0.15s;

			&:visited { color: $accent; }
			&:hover, &.active { color: $baseTextColor; }
		}
	}
}

.body {
	h1 {
		font-size: 2em;
		font-weight: 800;
		text-transform: capitalize;
		line-height: 1;
	}
	h2 {
		margin-bottom: 0.5rem;
		
		font-size: 2em;
		font-weight: 800;
		text-transform: capitalize;
		line-height: 1;
	}
	h3 {
		margin: 0.5em 0;
	}
	p {
		margin: 0 0 1.5em;
		line-height: 1.4;
		max-width: 1000px;
	}
	ul {
		margin: 0.5em 0;

		li {
			margin-left: 25px;
			list-style: circle;
		}
	}

	.work {
		overflow: hidden;
		margin-bottom: 1rem;

		ul {
			display: flex;
			flex-wrap: wrap;
			margin: -5px;
		}
		li {
			display: block;
			width: 25%;
			padding: 5px;
			margin: 0;
			list-style: none;

			@include bp($l) { width: 33.333%; }
			@include bp($m) { width: 50%; }
			@include bp($s) { width: 100%; }

			a {
				display: block;
				width: 100%;
				min-height: 100px;
				background: #CCC;
			}
			img { display: block; }
		}
	}

	.columns {

		.column {
			width: 100%;
			border: 1px solid #{$baseTextColor};
			border-width: 1px 0;
			@include bp($s) {
				&:nth-of-type(1) { border-bottom: none; }
				&:nth-of-type(2) { border-top: none; }
			}

			&__content {
				padding: 20px 0;
				& > *:first-child { margin-top: 0 !important; }
			}
		}

		@include bpmin($s) {
			display: flex;
			justify-content: space-between;

			.column__content {
				height: 100%;
			}

			.column:nth-of-type(1) {
				width: 33.333%;
				padding-right: 10px;
				margin-right: 20px;
				max-width: 300px;
				min-width: 200px;
			}
		}

		img {
			display: block;
			width: 100%;
			padding-bottom: 1.5em;
		}

		.details {
			h3 {
				font-style: italic;
			}
			p {
				margin: 0.5em 0;
			}
		}
		.content {
			h3 {
				font-size: 1.5em;
				font-weight: 800;
				text-transform: uppercase;
			}
			.contact_block {
				display: inline-block;
				width: 48%;
				margin-bottom: 0.5em;

				@include bp($m) {
					width: 100%;
				}
			}
			ul.socials {
				margin-bottom: 2em;
			}

			form {
				input, textarea, .submit {
					margin-bottom: 1em;
				}
				input {
					max-width: 300px;
				}
				textarea {
					max-width: 80%;
					min-height: 8em;
					@include bp($s) {
						max-width: 100%;
					}
				}
			}
		}
	}
}
footer {
	width: 100%;
	padding: 40px 0;

	.social {
		display: inline-block;
		vertical-align: middle;

		li {
			display: inline-block;
			margin-right: 0.5em;
		}
		a {
			display: flex;
			align-items: center;
			justify-content: center;

			text-decoration: none;
		}
		svg {
			width: 20px;
			height: 20px;
			fill: $baseTextColor;
		}
		a.linkedin svg { padding: 0 2px; }
	}
	.copyright {
		display: inline-block;
		vertical-align: middle;
		font-weight: 400;
	}
}

