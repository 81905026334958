@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,900';
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Site Vars */
/* Colors */
* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  font: 16px/22px Source Sans Pro, Helvetica, Arial, sans-serif;
  font-weight: 200;
  line-height: 1.2;
  color: rgb(30, 10, 10);
  min-width: 275px;
  background: rgb(255, 250, 245);
  background: radial-gradient(circle, white 0%, rgb(255, 250, 245) 100%);
  background-attachment: fixed;
}

/* #Buttons
================================================== */
.button,
button,
input[type=submit],
input[type=reset],
input[type=button] {
  background: #eee; /* Old browsers */
  background: #eee -moz-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); /* FF3.6+ */
  background: #eee -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.2)), color-stop(100%, rgba(0, 0, 0, 0.2))); /* Chrome,Safari4+ */
  background: #eee -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); /* Chrome10+,Safari5.1+ */
  background: #eee -o-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); /* Opera11.10+ */
  background: #eee -ms-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); /* IE10+ */
  background: #eee linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); /* W3C */
  border: 1px solid #aaa;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #444;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.75);
  cursor: pointer;
  line-height: normal;
  padding: 8px 10px;
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover {
  color: #222;
  background: #ddd; /* Old browsers */
  background: #ddd -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%); /* FF3.6+ */
  background: #ddd -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.3)), color-stop(100%, rgba(0, 0, 0, 0.3))); /* Chrome,Safari4+ */
  background: #ddd -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%); /* Chrome10+,Safari5.1+ */
  background: #ddd -o-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%); /* Opera11.10+ */
  background: #ddd -ms-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%); /* IE10+ */
  background: #ddd linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%); /* W3C */
  border: 1px solid #888;
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
}

.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active {
  border: 1px solid #666;
  background: #ccc; /* Old browsers */
  background: #ccc -moz-linear-gradient(top, rgba(255, 255, 255, 0.35) 0%, rgba(10, 10, 10, 0.4) 100%); /* FF3.6+ */
  background: #ccc -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.35)), color-stop(100%, rgba(10, 10, 10, 0.4))); /* Chrome,Safari4+ */
  background: #ccc -webkit-linear-gradient(top, rgba(255, 255, 255, 0.35) 0%, rgba(10, 10, 10, 0.4) 100%); /* Chrome10+,Safari5.1+ */
  background: #ccc -o-linear-gradient(top, rgba(255, 255, 255, 0.35) 0%, rgba(10, 10, 10, 0.4) 100%); /* Opera11.10+ */
  background: #ccc -ms-linear-gradient(top, rgba(255, 255, 255, 0.35) 0%, rgba(10, 10, 10, 0.4) 100%); /* IE10+ */
  background: #ccc linear-gradient(top, rgba(255, 255, 255, 0.35) 0%, rgba(10, 10, 10, 0.4) 100%); /* W3C */
}

.button.full-width,
button.full-width,
input[type=submit].full-width,
input[type=reset].full-width,
input[type=button].full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* #Forms
================================================== */
form {
  margin-bottom: 20px;
}

fieldset {
  margin-bottom: 20px;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
textarea,
select {
  border: 1px solid #ccc;
  padding: 6px 4px;
  outline: none;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font: 13px "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #777;
  margin: 0;
  width: 100%;
  display: block;
  background: #fff;
}

select {
  padding: 0;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
textarea:focus {
  border: 1px solid #aaa;
  color: #444;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

textarea {
  min-height: 60px;
}

label,
legend {
  display: block;
  font-weight: bold;
  font-size: 13px;
}

select {
  width: 220px;
}

input[type=checkbox] {
  display: inline;
}

label span,
legend span {
  font-weight: normal;
  font-size: 13px;
  color: #444;
}

/* generic styles */
::selection {
  background: rgb(30, 10, 10);
  color: rgb(255, 250, 245);
}

pre {
  font-family: monospace;
  white-space: pre;
  text-align: left;
}

a {
  outline: none;
  color: rgb(130, 100, 100);
}
a:visited {
  color: rgb(130, 100, 100);
}
a:hover {
  text-decoration: none;
  color: rgb(130, 100, 100);
}

strong {
  font-weight: 800;
}

em {
  font-style: italic;
}

.big {
  font-size: 2em;
  line-height: 1;
}

.overflow {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

div.container {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
}

header {
  line-height: 1;
  margin: 40px 0 40px;
}
header svg.logo {
  display: none;
  fill: rgb(30, 10, 10);
  width: 80%;
  max-width: 500px;
  margin: 0 0 10px;
}
header svg.logo.active {
  display: block;
}
header .japanese_note {
  display: none;
}
header .japanese_note.show {
  display: block;
}
header h1 {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: -1px;
  text-transform: lowercase;
  color: rgb(30, 10, 10);
}
header nav {
  margin-top: 5px;
  text-transform: lowercase;
  font-size: 28px;
  font-weight: 300;
}
header nav li {
  display: inline-block;
}
header nav li:after {
  content: "/";
  color: rgb(30, 10, 10);
  padding-right: 0.2em;
}
header nav li:last-of-type:after {
  display: none;
}
header nav a {
  position: relative;
  display: inline-block;
  padding: 0 0.5em 0 0;
  text-decoration: none;
  color: rgb(130, 100, 100);
  transition: color 0.15s;
}
header nav a:visited {
  color: rgb(130, 100, 100);
}
header nav a:hover, header nav a.active {
  color: rgb(30, 10, 10);
}

.body h1 {
  font-size: 2em;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 1;
}
.body h2 {
  margin-bottom: 0.5rem;
  font-size: 2em;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 1;
}
.body h3 {
  margin: 0.5em 0;
}
.body p {
  margin: 0 0 1.5em;
  line-height: 1.4;
  max-width: 1000px;
}
.body ul {
  margin: 0.5em 0;
}
.body ul li {
  margin-left: 25px;
  list-style: circle;
}
.body .work {
  overflow: hidden;
  margin-bottom: 1rem;
}
.body .work ul {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.body .work li {
  display: block;
  width: 25%;
  padding: 5px;
  margin: 0;
  list-style: none;
}
@media (max-width: 1000px) {
  .body .work li {
    width: 33.333%;
  }
}
@media (max-width: 768px) {
  .body .work li {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .body .work li {
    width: 100%;
  }
}
.body .work li a {
  display: block;
  width: 100%;
  min-height: 100px;
  background: #CCC;
}
.body .work li img {
  display: block;
}
.body .columns .column {
  width: 100%;
  border: 1px solid rgb(30, 10, 10);
  border-width: 1px 0;
}
@media (max-width: 500px) {
  .body .columns .column:nth-of-type(1) {
    border-bottom: none;
  }
  .body .columns .column:nth-of-type(2) {
    border-top: none;
  }
}
.body .columns .column__content {
  padding: 20px 0;
}
.body .columns .column__content > *:first-child {
  margin-top: 0 !important;
}
@media (min-width: 500px) {
  .body .columns {
    display: flex;
    justify-content: space-between;
  }
  .body .columns .column__content {
    height: 100%;
  }
  .body .columns .column:nth-of-type(1) {
    width: 33.333%;
    padding-right: 10px;
    margin-right: 20px;
    max-width: 300px;
    min-width: 200px;
  }
}
.body .columns img {
  display: block;
  width: 100%;
  padding-bottom: 1.5em;
}
.body .columns .details h3 {
  font-style: italic;
}
.body .columns .details p {
  margin: 0.5em 0;
}
.body .columns .content h3 {
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
}
.body .columns .content .contact_block {
  display: inline-block;
  width: 48%;
  margin-bottom: 0.5em;
}
@media (max-width: 768px) {
  .body .columns .content .contact_block {
    width: 100%;
  }
}
.body .columns .content ul.socials {
  margin-bottom: 2em;
}
.body .columns .content form input, .body .columns .content form textarea, .body .columns .content form .submit {
  margin-bottom: 1em;
}
.body .columns .content form input {
  max-width: 300px;
}
.body .columns .content form textarea {
  max-width: 80%;
  min-height: 8em;
}
@media (max-width: 500px) {
  .body .columns .content form textarea {
    max-width: 100%;
  }
}

footer {
  width: 100%;
  padding: 40px 0;
}
footer .social {
  display: inline-block;
  vertical-align: middle;
}
footer .social li {
  display: inline-block;
  margin-right: 0.5em;
}
footer .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
footer .social svg {
  width: 20px;
  height: 20px;
  fill: rgb(30, 10, 10);
}
footer .social a.linkedin svg {
  padding: 0 2px;
}
footer .copyright {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
}