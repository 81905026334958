/* Site Vars */
$fontfamily: 'Source Sans Pro', Helvetica, Arial, sans-serif;

$f: 1200px;
$l: 1000px;
$m: 768px;
$s: 500px;

/* Colors */
$black: #231F20;
$lightgrey: #DCDEE0;
$grey: #53585F;
$midgrey: lighten($grey, 25%);
$offwhite: rgb(255,250,245);

$darkblue: rgb(10,10,30);
$darkred: rgb(30,10,10);
$midblue: rgb(100,100,130);
$midred: rgb(130,100,100);

$lightblueblock: rgb(100,100,222);
$lightblue: rgb(120,120,242);
$orange: rgb(240,90,44);

$defaultBgColor: $offwhite;
$baseTextColor: $darkred;
$accent: $midred;

$blackOverlay: rgba(0,0,0,0.75);